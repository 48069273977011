import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import firebase from 'firebase/app';

import 'bootstrap/dist/css/bootstrap.min.css';
import './stylesheets/index.css';

import store from './modules/store';

import App from './App';
import reportWebVitals from './reportWebVitals';

const firebaseConfig = {
  apiKey: "AIzaSyBBccZ9lv2KPoHMbbbLzDk1A0_MErd97Ms",
  authDomain: "h4ris-edf05.firebaseapp.com",
  databaseURL: "https://h4ris-edf05-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "h4ris-edf05",
  storageBucket: "h4ris-edf05.appspot.com",
  messagingSenderId: "768450221507",
  appId: "1:768450221507:web:040c2570ef81acb9a11976",
  measurementId: "G-X0GEZYZHF3"
};

firebase.initializeApp(firebaseConfig);

// DEV ONLY
// firebase.functions().useFunctionsEmulator('http://localhost:5001');

// Get a reference to the Firebase Firestore service
const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true });

ReactDOM.render(
  <React.StrictMode>
    <link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
