import uniqid from 'uniqid';
import _ from 'lodash';
import { User } from './types';

const initialState: (User | null) = null;

export default function(
  state = initialState,
  { type, payload } : { type: string, payload: any }
): (User | null) {
  switch (type) {
    case 'USER_SIGNED_IN':
      return {
        ...payload,
        wid: uniqid(`${payload.id}_`),
      };
    case 'USER_SIGNED_OUT':
      return null;
    default:
      return state;
  }
};
