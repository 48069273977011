import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import firebase from "firebase";
import formValidator from '../helpers/validator';
import Avatar from "../components/Member/Avatar";

////////////////////////////////////////////
// Styled Components ///////////////////////
////////////////////////////////////////////
const Container = styled.section`
  width: 100%;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 4px;
  max-width: 450px;
  color: #282c34;
  padding: 16px;
`;

export default function MainScreen() {
  const [partyName, setPartyName] = useState('');
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('ben');
  const user = useSelector((state: any) => state.user);

  const history = useHistory();

  useEffect(() => {
    formValidator.init();
  }, []);

  function createParty(name: string) {
    console.log(name);
    firebase
      .database()
      .ref(`parties/${user.id}`)
      .set({
        name: partyName,
        owner: user.id,
        questionsDuration: 30,
        questionsAmount: 30,
        members: {
          [user.id]: {
            name: userName,
            avatar: userAvatar,
            id: user.id,
          }
        },
      })
      .then(() => {
        history.push(`/party/${user.id}`);
      });
  }

  return (
    <Container>
      <Card className="container-sm">
        <form
          noValidate
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            createParty(partyName);
          }}>
          <fieldset>
            <legend>Créer une partie</legend>
            <div className="mb-3">
              <label htmlFor="party-name" className="form-label">Nom du salon</label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="party-name"
                placeholder="RLS City"
                value={partyName}
                onChange={(e) => setPartyName(e.target.value)}
                required
              />
              <div className="invalid-feedback">Veuillez entrer un nom de salon</div>
            </div>
            <div className="mb-3">
              <label htmlFor="user-name" className="form-label">Pseudo</label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="user-name"
                placeholder="Kevin"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
              <div className="invalid-feedback">Veuillez entrer un pseudo</div>
            </div>
            <div className="mb-3">
              <label className="form-label">Avatar</label>
              <Avatar value={userAvatar} size="medium" onChange={(avatar: string) => setUserAvatar(avatar)} selectionMode />
            </div>
            <button type="submit" className="btn btn-primary">Créer</button>
          </fieldset>
        </form>
      </Card>
    </Container>
  );
}
