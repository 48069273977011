import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import moment from 'moment';

import formValidator from '../helpers/validator';
import Avatar from "../components/Member/Avatar";

////////////////////////////////////////////
// Styled Components ///////////////////////
////////////////////////////////////////////
const Container = styled.section`
  width: 100%;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 4px;
  max-width: 450px;
  color: #282c34;
  padding: 16px;
`;

export default function MainScreen() {
  const user = useSelector((state: any) => state.user);
  const [userName, setUserName] = useState('');
  const [userAvatar, setUserAvatar] = useState('ben');
  const { partyId } : any = useParams();

  const history = useHistory();

  useEffect(() => {
    firebase
      .database()
      .ref(`/parties/${partyId}`)
      .once('value')
      .then((snapshot) => {
        const data = snapshot.val();
        if (!data) {
          history.replace('/');
        }
      });

    formValidator.init();
  }, []);

  function joinParty() {
    firebase
      .database()
      .ref(`parties/${partyId}/members/${user.id}`)
      .set({
        name: userName,
        avatar: userAvatar,
        lastPing: moment().unix(),
        id: user.id,
      })
      .then(() => {
        history.push(`/party/${partyId}`);
      });
  }

  return (
    <Container>
      <Card className="container-sm">
        <form
          noValidate
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            joinParty();
          }}>
          <fieldset>
            <legend>Rejoindre</legend>
            <div className="mb-3">
              <label htmlFor="user-name" className="form-label">Pseudo</label>
              <input
                type="text"
                name="name"
                className="form-control"
                id="user-name"
                placeholder="Kevin"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
              <div className="invalid-feedback">Veuillez entrer un pseudo</div>
            </div>
            <div className="mb-3">
              <label className="form-label">Avatar</label>
              <Avatar value={userAvatar} size="medium" onChange={(avatar: string) => setUserAvatar(avatar)} selectionMode />
            </div>
            <button type="submit" className="btn btn-primary">Rejoindre</button>
          </fieldset>
        </form>
      </Card>
    </Container>
  );
}
