import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Notification } from '../../modules/reducers/notifications/types';
import { Toast } from 'bootstrap';

////////////////////////////////////////////
// Styled Components ///////////////////////
////////////////////////////////////////////
const $Toast = styled.div`
  .toast-header .me-auto, .toast-body {
    color: #282c34;
  }
`;
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////

export default function Notifications() {
  const notifications = useSelector((state: any) => state.notifications);
  const dispatch = useDispatch();

  useEffect(() => {
    notifications.list.forEach((notification: Notification) => {
      const toastEl = document.getElementById(`toast-${notification.id}`);

      if (toastEl && !toastEl.classList.contains('show')) {
        const toast = new Toast(toastEl, {
          animation: true,
          autohide: false,
        });

        setTimeout(() => {
          toast.hide();
        }, 5000);

        // @ts-ignore
        toastEl.addEventListener('hidden.bs.toast', () => {
          console.log('hidden', toastEl);
          dispatch({
            type: 'NOTIFICATION_DELETE',
            payload: { id: notification.id }
          });
        });

        toast.show();
      }
    });
  }, [notifications.list]);

  return (
    <div aria-live="polite" aria-atomic="true">
      <div className="toast-container position-absolute top-0 end-0 p-3">
        {
          notifications.list && notifications.list.map((notification: Notification) => (
            <$Toast id={`toast-${notification.id}`} className="toast" role="alert" aria-live="assertive" aria-atomic="true" key={notification.id}>
              <div className="toast-header">
                <strong className="me-auto">{ notification.title }</strong>
                <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" />
              </div>
              <div className="toast-body">
                { notification.content }
              </div>
            </$Toast>
          ))
        }
      </div>
    </div>
  );
}
