import React from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from "react-router-dom";

////////////////////////////////////////////
// Styled Components ///////////////////////
////////////////////////////////////////////
const Container = styled.section`
  width: 100%;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 4px;
  max-width: 450px;
  color: #282c34;
  padding: 16px;
  text-align: center;
`;

export default function MainScreen() {
  const { partyId } : any = useParams();

  const history = useHistory();

  function joinParty() {
    history.push(`/party/${partyId}`);
  }

  return (
    <Container>
      <Card className="container-sm">
        <h4>Vous vous êtes connecté ailleurs</h4>
        <button className="btn btn-link" onClick={joinParty}>
          Se reconnecter ici
        </button>
      </Card>
    </Container>
  );
}
