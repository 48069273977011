import { Notifications } from './types';
import moment from 'moment';
import _ from 'lodash';

const initialState: Notifications = {
  list: [],
};

export default function(
  state = initialState,
  {
    type,
    payload,
  } : {
    type: string,
    payload: any,
  }
): Notifications {
  switch (type) {
    case 'NOTIFICATION_CREATE': {
      return {
        list: [
          ...state.list,
          {
            ...payload,
            id: moment().unix(),
          },
        ]
      };
    }
    case 'NOTIFICATION_DELETE': {
      return {
        list: [
          ..._.reject(
            state.list,
            { id: payload.id }
          )
        ]
      }
    }
    default:
      return state;
  }
};
