import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import firebase from 'firebase';
import 'firebase/auth';


import Notifications from './components/Notifications';

import MainScreen from './screens/Main';
import PartyScreen from './screens/Party';
import JoinScreen from './screens/Join';
import DisconnectScreen from './screens/Disconnect';

////////////////////////////////////////////
// Styled Components ///////////////////////
////////////////////////////////////////////
const $Container = styled.div`
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 16px;
`;

const $Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////

export default function App() {
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      dispatch({
        type: user ? 'USER_SIGNED_IN' : 'USER_SIGNED_OUT',
        payload: user
          ? {
            id: user.uid
          }
          : null
      });
    });

    firebase
      .auth()
      .signInAnonymously()
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return user
    ? (
      <$Container>
        <Router>
          <Switch>
            <Route path="/party/:partyId">
              <PartyScreen />
            </Route>
            <Route path="/join/:partyId/disconnect">
              <DisconnectScreen />
            </Route>
            <Route path="/join/:partyId">
              <JoinScreen />
            </Route>
            <Route path="/">
              <MainScreen />
            </Route>
          </Switch>
        </Router>

        {/* Notificatons - Toasters */}
        <Notifications />
      </$Container>
    )
    : (
      <$Loader>
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </$Loader>
    );
}
