import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

const $TimerContainer = styled.div`
  background-color: #ffe7dc;
  border-radius: 4px;
  overflow: hidden;
  
  div {
    // height: 5px;
    background: #ff5200;
    transition: width linear 1s;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
`;

let itv: any;

export default function Timer({ endTime, duration, onChange }: { endTime: any, duration: number, onChange: any}) {
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    console.log('SOMETHING CHANGED', endTime);
    if (!itv) {
      itv = setInterval(() => {
        const diff = Math.max(0, endTime - moment().unix());
        setTimeLeft(diff);

        onChange(diff);
        if (diff <= 0) {
          clearInterval(itv);
          itv = null;
        }
      }, 1000);
    }

    return () => clearInterval(itv);
  }, [endTime]);

  return (
    <$TimerContainer className="mb-lg-5 mb-md-3 mb-3">
      <div style={{ width: `${(((timeLeft > duration) ? duration : timeLeft)/duration)*100}%` }}>
        {(timeLeft > duration) ? duration : timeLeft}s
      </div>
    </$TimerContainer>
  );
}