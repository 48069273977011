import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Modal} from 'bootstrap';

import firebase from 'firebase/app';
import 'firebase/database';

import {useHistory, useParams} from "react-router-dom";
import moment from 'moment';
import _ from 'lodash';
import Avatar from "../components/Member/Avatar";
import Timer from "../components/Timer";

const questions : { [k: string]: { name: string, thumbnail: string, id: string, data: any }[] } = require('../questionlist.json');

////////////////////////////////////////////
// Styled Components ///////////////////////
////////////////////////////////////////////
const $Container = styled.section`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const $CardContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;

const $Card = styled.div`
  background-color: #fff;
  border-radius: 4px;
  max-width: 768px;
  color: #282c34;
  padding: 16px;
  
  h3, h6 {
    opacity: 1;
    transition: opacity ease .1s;
    
    &.hidden {
      opacity: 0.8;
    }
  }
`;

const $AnswerOption = styled.div`
  label {
    opacity: 1;
    width: 100%;
    text-align: center;
    transition: opacity .1s ease;
    
    &.hidden {
      opacity: 0.8 !important;
    }
  }
`;

const $Footer = styled.div`
  padding: 16px 16px 0 16px;
  text-align: center;
  
  h3 {
    margin-bottom: 16px;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    overflow-x: auto;
  }
`;

const $Member = styled.li`
  display: inline-block;
  border-radius: 4px;
  padding: 8px;
  position: relative;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  
  &.inactive {
    opacity: 0.5;
    
    & > div:first-child {
      background-color: #93a293;
    }
  }
  
  .is-owner {
    font-weight: bold;
    text-decoration: underline;
  }
`;

const $OnlineStatus = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  position: absolute;
  top: 5px;
  right: calc(50% - 55px);
  background-color: #228b22;
  
  &.inactive {
    background-color: #93a293;
  }
`;

const $Modal = styled.div`
  color: #282c34;
`;

const $ThemeList = styled.ul`
  list-style: 'none';
  padding: 0 0 16px 0;
  display: flex;
  flex-wrap: wrap;
  clear: both;
  border-bottom: 1px solid #282c34;
  
  li {
    display: flex;
    margin: 5px;
    flex: 1 0 calc(33% - 10px);
    max-width: calc(33% - 10px);
    min-height: 50px;
    
    label {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      font-size: 0.8em;
      font-weight: 600;
      
      &:active {
        background: #4b6c8a;
      }
    }
  }
`;

const $ThemeHeader = styled.div`
  align-items: center;
  
  h5 {
    margin: 0;
    float: left;
    padding: 6px 0 12px 0;
  }
  
  button {
    float: right;
    font-size: 0.8em;
    font-weight: bold;
    color: #377ade;
    text-decoration: none;
  }
`;

const $ThemeListSub = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  li {
    display: flex;
    border: 1px solid #ecf1f5;
    border-radius: 4px;
    font-size: 0.6em;
    font-weight: bold;
    flex: 1 0 calc(25% - 10px);
    text-align: center;
    padding: 4px 4px;
    background: #ecf1f5;
    margin: 2px 5px;
    max-width: calc(25% - 10px);
    color: #282c34;
    justify-content: center;
    align-items: center;
  }
`;

const $Loader = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0;
  justify-content: center;
  align-items: center;
`;
////////////////////////////////////////////
////////////////////////////////////////////
////////////////////////////////////////////

let updatePartySettings = (options: object) => {};

export default function MainScreen() {
  const db = firebase.database();

  const { partyId } : any = useParams();

  const [modal, setModal] = useState<Modal>();
  const [categories, setCategories] = useState<null | { [k: string]: { name: string, id: string, thumbnail: string, data: any }[] }>(null);
  const [selectedThemes, setSelectedThemes] = useState<{ [k: string]: string[] }>({});
  const [partyData, setPartyData] = useState<null | IParty>(null);
  const [questionAnswer, setQuestionAnswer] = useState<string>('');
  const [questionsDuration, setQuestionsDuration] = useState<number>(30);
  const [questionsAmount, setQuestionsAmount] = useState<number>(30);
  const [isTimeOut, setIsTimeOut] = useState<boolean>(false);
  const [isTimeOver, setIsTimeOver] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] = useState<any>(null);
  const [currentQuestionStartTime, setCurrentQuestionStartTime] = useState<any>(null);

  const user = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();

  // console.log(questions);

  function toggleTheme(category: string, themeId?: (string | undefined)) {
    const cloned = selectedThemes;

    if (themeId) {
      if (!cloned[category]) {
        cloned[category] = [];
      }

      if (~cloned[category].indexOf(themeId)) {
        cloned[category] = _.without(cloned[category], themeId);
      } else {
        cloned[category].push(themeId);
      }
    } else {
      cloned[category] = !cloned[category] || cloned[category].length === 0
        ? _.map(questions[category], 'id')
        : [];
      // tmpSelections[category] = _.map(questions[category], 'name');
    }

    updatePartySettings({ selectedThemes: { ...cloned }});
    setSelectedThemes({ ...cloned });
  }

  function startQuizz() {
    setIsLoading(true);

    const getQuestions = firebase.app().functions('europe-west1').httpsCallable('getQuestions');
    // const getQuestions = firebase.functions().httpsCallable('getQuestions');
    const selectedIds = _.flatten(_.map(selectedThemes));

    getQuestions({
      partyId: partyId,
      ids: selectedIds,
      amount: questionsAmount,
    })
      .then(() => {
        // console.log('????');
        const partyRef = db.ref(`parties/${partyId}`);
        const time = moment().add(questionsDuration + 3, 's');
        partyRef
          .update({
            currentQuestion: 1,
            questionStartTime: time.unix(),
          })
          .then(() => {
            setIsLoading(false);
          });
      })
      .catch((e) => {
        console.error(e);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setCategories(questions);

    let pingItv: any;
    let isFirstFetch = true;
    let isWIDUpdated = false;
    let party: IParty;
    let isOwner: Boolean = false;
    let diffMembers: { [k: string]: IMember } = {};

    // Refs
    const partyRef = db.ref(`parties/${partyId}`);
    const membersRef = db.ref(`parties/${partyId}/members`);
    const userRef = db.ref(`parties/${partyId}/members/${user.id}`);

    partyRef.on('value', (snapshot) => {
      const data = snapshot.val();

      if (data && data.members && data.members[user.id]) {
        isOwner = data.owner === user.id;
        party = data;

        // Build default selected themes object
        const tmpSelections : { [k: string]: string[] } = {};
        for (let category in questions) {
          if (questions.hasOwnProperty(category)) {
            tmpSelections[category] = _.map(questions[category], 'id');
          }
        }

        if (isFirstFetch) {
          isFirstFetch = false;
          diffMembers = data.members;

          setQuestionsAmount(party.questionsAmount || 15);
          setQuestionsDuration(party.questionsDuration || 30);
          setSelectedThemes(party.selectedThemes || tmpSelections);

          if (party.questions && party.currentQuestion && party.questionStartTime) {
            const question = party.questions[party.currentQuestion];
            setCurrentQuestion(question);
            setCurrentQuestionStartTime(party.questionStartTime);
            // setCurrentQuestionStartTime(moment().add(30, 's'));
          }

          if (party.started && !party.currentQuestion && isOwner) {
            partyRef.update({
              currentQuestion: 1,
              questionStartTime: moment().add(5, 's').unix(),
            });
          }

          userRef
            .update({
              wid: user.wid,
              isOffline: false,
              lastPing: moment().unix(),
            })
            .then(() => {
              isWIDUpdated = true;

              membersRef.on('child_added', (d) => {
                const memberId = d.key;
                if (memberId && !diffMembers[memberId]) {
                  const newMember = d.val();
                  diffMembers[memberId] = newMember;

                  dispatch({
                    type: 'NOTIFICATION_CREATE',
                    payload: {
                      title: 'Nouveau joueur',
                      content: `${newMember.name} vient de rejoindre le salon`,
                    }
                  });
                }
              });

              membersRef.on('child_changed', (d) => {
                const memberId = d.key;
                if (memberId && diffMembers[memberId]) {
                  if (d.val().isOffline && !diffMembers[memberId].isOffline) {
                    dispatch({
                      type: 'NOTIFICATION_CREATE',
                      payload: {
                        title: 'Joueur déconnecté',
                        content: `${diffMembers[memberId].name} s'est déconnecté`,
                      }
                    });
                  } else if (!d.val().isOffline && diffMembers[memberId].isOffline) {
                    dispatch({
                      type: 'NOTIFICATION_CREATE',
                      payload: {
                        title: 'Joueur reconnecté',
                        content: `${diffMembers[memberId].name} s'est reconnecté`,
                      }
                    });
                  }

                  diffMembers[memberId] = d.val();
                }
              });

              // Ping DB - User presence mechanism
              pingItv = setInterval(() => {
                userRef.update({
                  lastPing: moment().unix()
                });

                if (isOwner && party && party.members) {
                  for (let memberId in party.members) {
                    if (party.members.hasOwnProperty(memberId) && party.members[memberId].lastPing && !party.members[memberId].isOffline) {
                      const diff = (moment().unix() - party.members[memberId].lastPing);
                      if (diff > 30) {
                        db.ref(`parties/${partyId}/members/${memberId}`).update({ isOffline: true });
                      }
                    }
                  }
                }
              }, 5000);
            });
        }

        // Allow single window only
        if (isWIDUpdated && party.members[user.id].wid !== user.wid) {
          history.replace(`/join/${partyId}/disconnect`);
        }

        if (!isOwner) {
          setQuestionsAmount(party.questionsAmount || 15);
          setQuestionsDuration(party.questionsDuration || 30);
          setSelectedThemes(party.selectedThemes || tmpSelections);
        }

        if (party.questions && party.currentQuestion && party.questionStartTime) {
          const question = party.questions[party.currentQuestion];
          setCurrentQuestion(question);
          setCurrentQuestionStartTime(party.questionStartTime);
        }

        setPartyData(party);
      } else {
        history.replace(`/join/${partyId}`);
      }
    });

    return () => {
      clearInterval(pingItv);
      partyRef.off();
      membersRef.off();
    };
  }, []);

  useEffect(() => {
    const el = document.getElementById('staticBackdrop');
    if (el) {
      setModal(new Modal(el));
    }
  }, []);

  useEffect(() => {
    const partyRef = db.ref(`parties/${partyId}`);

    updatePartySettings = _.debounce(function (options) {
      partyRef.update(options);
    }, 200);
  }, []);

  useEffect(() => {
    console.log('IsTimeOut', isTimeOut);
    if (isTimeOut && currentQuestion && partyData && partyData.owner === user.id) {
      const partyRef = db.ref(`parties/${partyId}`);

      if (partyData.currentQuestion < (partyData.questions.length - 1)) {
        const time = moment().add(questionsDuration + 3, 's').unix();
        const nextQuestion = (partyData.currentQuestion + 1);
        setTimeout(() => {
          partyRef
            .update({
              currentQuestion: nextQuestion,
              questionStartTime: time,
            });

          setCurrentQuestionStartTime(time);
          setCurrentQuestion(partyData.questions[nextQuestion]);
        }, 1500);
      } else {
        const results: any = [];
        const score: any = {};

        for (let member in partyData.members) {
          if (partyData.members.hasOwnProperty(member)) {
            score[member] = 0;
          }
        }

        partyData.questions.forEach((question: any, index: number) => {
          const r: any = {
            question: question.question,
            answer: question['réponse'],
            difficulty: question.difficulty,
            anecdote: question.anecdote,
            members: {},
          };

          for (let member in partyData.members) {
            if (partyData.members.hasOwnProperty(member)) {
              const answer = partyData.members[member].answers && partyData.members[member].answers[index] || '';
              const isCorrect = answer === question['réponse'];
              if (isCorrect) {
                score[member] = score[member] + question.difficulty;
              }
              r.members[member] = {
                answer,
                isCorrect
              };
            }
          }

          results.push(r);
        });

        partyRef
          .update({
            ended: true,
            results,
            score,
          });
      }
      /*partyRef.update({
        currentQuestion: 1,
        questionStartTime: moment().add(5, 's').unix(),
      })*/
    }
  }, [isTimeOut]);

  useEffect(() => {
    if (partyData) {
      const userRef = db.ref(`parties/${partyId}/members/${user.id}/answers`);
      userRef.update({
        [partyData.currentQuestion]: questionAnswer,
      }).then(() => {
        // console.log('Updated');
      }).catch((e) => {
        console.error(e);
      });
    }
  }, [questionAnswer]);

  return (
    <$Container>
      {
        partyData && (
          <>
            {
              partyData.started
                ? (
                  <$CardContainer>
                    <$Card className="container-md">
                      {
                        (currentQuestion && currentQuestionStartTime && !partyData.ended)
                          ? (
                            <fieldset disabled={isTimeOut || isTimeOver}>
                              <legend className="mb-lg-4">Question {partyData.currentQuestion + 1}/{partyData.questions.length}:</legend>
                              <div className="mb-lg-5 mb-4">
                                <h5 style={{ textAlign: 'center' }}>{_.capitalize(currentQuestion.category)} &gt; {currentQuestion.name}</h5>
                                <hr />
                                <h3 className={isTimeOver ? 'hidden' : ''} style={{ textAlign: 'center' }}>{ currentQuestion.question }</h3>
                                <h6 className={isTimeOver ? 'hidden' : ''} style={{ textAlign: 'center' }}>{ currentQuestion.difficulty } points</h6>
                              </div>
                              <Timer
                                endTime={currentQuestionStartTime}
                                duration={partyData.questionsDuration}
                                onChange={(ended: number) => {
                                  setIsTimeOut(ended <= 0);
                                  setIsTimeOver(ended > (partyData.questionsDuration * 1000));
                                }} />
                              <label htmlFor="questions-duration" className="form-label"><b>Choisissez la bonne réponse:</b></label>
                              {
                                currentQuestion.propositions.map((proposition: any, index: number) => (
                                  <$AnswerOption className="mb-1" key={`question-proposition-${partyData.currentQuestion}-${index}`}>
                                    { questionAnswer }
                                    <input
                                      type="radio"
                                      className="btn-check"
                                      name="question-answer"
                                      id={`proposition-${index}`}
                                      value={proposition}
                                      checked={questionAnswer === proposition}
                                      onChange={(e) => setQuestionAnswer(e.target.value)}
                                      autoComplete="off" />
                                    <label className={`btn btn-outline-secondary ${isTimeOver ? 'hidden' : ''}`} htmlFor={`proposition-${index}`}>{ proposition }</label>
                                  </$AnswerOption>
                                ))
                              }
                            </fieldset>
                          )
                          : (
                            <>
                              {
                                partyData.results && partyData.score
                                  ? (
                                    <>
                                      <ul style={{ listStyle: 'none', padding: 0 }}>
                                        {
                                          _.map(
                                            _.sortBy(
                                              _.map(
                                                partyData.score,
                                                (r: any, index: string) => ({ name: partyData.members[index].name, score: r }),
                                              ),
                                              'score',
                                            ).reverse(),
                                            (member, index) => (
                                              <li style={{ padding: 8, border: '1px solid #fafafa', background: '#f3f3f3', marginBottom: 4, borderRadius: 4}} key={`r-member-${index}`}>
                                                <div>
                                                  <b>{member.name}</b><div style={{ float: 'right' }}>{ member.score } points</div>
                                                </div>
                                              </li>
                                            ),
                                          )
                                        }
                                      </ul>
                                      <hr />
                                      <ul style={{ listStyle: 'none', padding: 0 }}>
                                        {
                                          _.map(partyData.results, (question, index) => (
                                            <li key={`question-${index}`} style={{ marginBottom: 16, border: '1px solid #fafafa', padding: 16, background: '#f3f3f3', borderRadius: 8 }}>
                                              <b>Question {index + 1} ({question.difficulty} points):</b> { question.question }<br />
                                              <b>Réponse:</b> { question.answer }<br />
                                              <b>Anecdote:</b> { question.anecdote }<br />
                                              <ul style={{ listStyle: 'none' }}>
                                                {
                                                  _.map(question.members, (member, index) => (
                                                    <li style={{ color: member.isCorrect ? 'green' : 'red' }}>
                                                      <b>{partyData.members[index].name}:</b> { member.answer }
                                                    </li>
                                                  ))
                                                }
                                              </ul>
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </>
                                  )
                                  : (
                                    <$Loader>
                                      <div className="spinner-border text-black-50" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                      </div>
                                    </$Loader>
                                  )
                              }
                            </>
                          )
                      }
                    </$Card>
                  </$CardContainer>
                )
                : (
                  <$CardContainer>
                    <$Card className="container-md">
                      <fieldset disabled={(partyData.owner !== user.id) || isLoading}>
                        <legend>{ partyData.name }</legend>
                        <label htmlFor="questions-duration" className="form-label"><b>Temps par question:</b> {questionsDuration} secondes</label>
                        <div className="input-group mb-3">
                          <input
                            type="range"
                            className="form-range"
                            min="5"
                            max="60"
                            step="1"
                            id="questions-duration"
                            value={questionsDuration}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value) || 15;
                              setQuestionsDuration(newValue);
                              updatePartySettings({ questionsDuration: newValue });
                            }}
                          />
                        </div>
                        <label htmlFor="questions-amount" className="form-label"><b>Nombre de questions:</b> { questionsAmount }</label>
                        <div className="input-group mb-3">
                          <input
                            type="range"
                            className="form-range"
                            min="5"
                            max="100"
                            step="1"
                            id="questions-amount"
                            value={questionsAmount}
                            onChange={(e) => {
                              const newValue = parseInt(e.target.value) || 30;
                              setQuestionsAmount(newValue);
                              updatePartySettings({ questionsAmount: newValue });
                            }}
                          />
                        </div>
                        <label htmlFor="questions-amount" className="form-label"><b>Thèmes sélectionnés:</b></label>
                        <div className="input-group mb-3">
                          <$ThemeListSub>
                            {
                              _.map(selectedThemes, (sub, category) => {
                                const count = sub.length;
                                return count ? (<li key={category}>{ category } ({`${sub.length}/${categories && _.size(categories[category])}`})</li>) : null;
                              })
                            }
                          </$ThemeListSub>
                        </div>
                        {
                          partyData.owner === user.id && (
                            <>
                              <div className="mb-1">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => { modal && modal.show() }}
                                  style={{ width: '100%' }}
                                >
                                  Selection des thèmes
                                </button>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary"
                                style={{ width: '100%' }}
                                onClick={() => startQuizz()}
                              >
                                Lancer
                              </button>
                            </>
                          )
                        }
                      </fieldset>
                    </$Card>
                  </$CardContainer>
                )
            }

            <$Footer>
              <h3>Lobby ({_.size(_.reject(partyData.members, 'isOffline'))})</h3>
              <ul>
                {
                  _.map(_.reject(partyData.members, 'isOffline'), (data, id) => (
                    <$Member
                      className={(moment().unix() - data.lastPing) >= 15 ? 'inactive' : ''}
                      key={data.id}
                    >
                      <$OnlineStatus/>
                      <Avatar value={data.avatar} />
                      <span title={data.name} className={(partyData.owner === data.id) ? 'is-owner' : ''}>
                        { data.name }
                      </span>
                    </$Member>
                  ))
                }
              </ul>
            </$Footer>
          </>
        )
      }

      <$Modal className="modal fade" id="staticBackdrop">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Sélection des thèmes</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              {
                categories && _.map(categories, (themes, categoryName) => {
                  return (
                    <div key={categoryName}>
                      <$ThemeHeader>
                        <h5>
                          { _.capitalize(categoryName) } ({(selectedThemes[categoryName] && selectedThemes[categoryName].length) || 0}/{themes.length})
                        </h5>
                        <button className="btn btn-link" onClick={() => toggleTheme(categoryName)}>
                          Tout {selectedThemes[categoryName] && selectedThemes[categoryName].length ? 'déselectionner' : 'sélectionner'}
                        </button>
                      </$ThemeHeader>
                      <$ThemeList>
                        {
                          themes.map((theme) => (
                            <li key={theme.name}>
                              <input
                                type="checkbox"
                                className="btn-check"
                                id={`btn-theme-${_.kebabCase(theme.name)}`}
                                autoComplete="off"
                                checked={_.includes(selectedThemes[categoryName], theme.id)}
                                onChange={() => { toggleTheme(categoryName, theme.id) }}
                              />
                              <label
                                className="btn btn-outline-secondary"
                                htmlFor={`btn-theme-${_.kebabCase(theme.name)}`}
                              >{ theme.name }</label>
                            </li>
                          ))
                        }
                      </$ThemeList>
                    </div>
                  );
                })
              }
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
            </div>
          </div>
        </div>
      </$Modal>
    </$Container>
  );
}

interface IMember {
  id: string,
  name: string,
  avatar: string,
  lastPing?: any,
  isOffline?: boolean,
  wid: string,
  answers?: any,
}

interface IParty {
  name: string,
  owner: string,
  members: any, // IMember[],
  questionsDuration: number,
  questionsAmount: number,
  selectedThemes?: any,
  started?: boolean,
  currentQuestion?: any,
  questionStartTime?: number,
  questions?: any,
  ended?: boolean,
  results?: any,
  score?: any,
}
