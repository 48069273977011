import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import fs from 'fs';

const $AvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.view {
    width: 100px;
    height: 100px;

    &.medium {
      width: 150px;
      height: 150px;
    }

    &.big {
      width: 200px;
      height: 200px;
    }
  }
  
  button {
    margin: 0 16px;
    border: 0;
    background: transparent;
  }
`;

const $Avatar = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-size: cover;
  
  &.big {
    width: 200px;
    height: 200px;
  }
  
  &.medium {
    width: 150px;
    height: 150px;
  }
`;

const AVATARS = [
  'baby',
  'ben',
  'bg',
  'blase',
  'caca',
  'chat',
  'chat2',
  'choco',
  'face1',
  'flo',
  'fred',
  'freda',
  'fred2',
  'gavin',
  'Hal',
  'hiboux',
  'jim',
  'love',
  'mad',
  'marc',
  'marc2',
  'matrix',
  'misterV1',
  'moine',
  'moine2',
  'perruque',
  'philippe',
  'scissors',
  'smoke',
  'soso',
  'tb',
  'Thierno',
  'zepeck',
];

export default function Avatar(
  { value, onChange, selectionMode, size }:
    { value: string, onChange?: any, selectionMode?: boolean, size?: string }
) {
  function changeAvatar(pos: number) {
    if (!onChange) {
      return;
    }

    let newPos = AVATARS.indexOf(value) + pos;

    if (newPos < 0) {
      onChange(AVATARS[AVATARS.length - 1]);
    } else if (newPos >= AVATARS.length) {
      onChange(AVATARS[0]);
    } else {
      onChange(AVATARS[newPos]);
    }
  }
  // background-image: url('${process.env.PUBLIC_URL}/avatar/ben.jpg');
  return (
    <$AvatarContainer className={`${size} ${!selectionMode ? 'view' : ''}`}>
      { selectionMode && (
        <button onClick={(e) => {
          e.preventDefault();
          changeAvatar(-1);
        }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} />
        </button>
      )}
      <$Avatar
        className={size}
        style={{ backgroundImage: `url('${process.env.PUBLIC_URL}/avatar/${value}.jpg')` }}
      />
      { selectionMode && (
        <button onClick={(e) => {
          e.preventDefault();
          changeAvatar(1);
        }}>
          <FontAwesomeIcon icon={faArrowCircleRight} />
        </button>
      )}
    </$AvatarContainer>
  );
}
